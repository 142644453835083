import React from "react"
import "../../works/susfinteq.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import susfinteq from "../../../../static/work_headers/susfinteq.png"
import Dict from "../../../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import susfinteq2 from "../../../../static/works_perks/susfinteq_2.png"
import susfinteq1 from "../../../../static/works_perks/susfinteq_1.png"
import Contact from "../../../components/contact"
import logo from "../../../../static/logos/susfinteq_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="UI/UX Design - susfinteq"
      seoDescription="Il fallait pour cette société innovante une forte identité de marque. Pour cela, nous avons travaillé de près avec les co-fondateurs pour y infuser des éléments de technologie, finance et durabilité."
    >
      <div id="works-susfinteq-background">
        <WorkHeader
          title={dict.getTrans("work_susfinteq_title")}
          subTitle={dict.getTrans("work_susfinteq_subtitle")}
          pic={susfinteq}
          navLang={lang}
          hl="#22D8A3"
          pageUrl="/works/susfinteq"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("susfinteq_about_title")}
        p1={dict.getTrans("susfinteq_about_p1")}
        p2={dict.getTrans("susfinteq_about_p2")}
        logo={logo}
      />
      <div id="works-susfinteq-perks-holder">
        <Perk
          title={dict.getTrans("susfinteq_perk1_title")}
          image={susfinteq1}
          text={dict.getTrans("susfinteq_perk1_text")}
        />
        <Perk
          title={dict.getTrans("susfinteq_perk2_title")}
          image={susfinteq2}
          text={dict.getTrans("susfinteq_perk2_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
